import {
  DatePicker,
  Form,
  FormProps,
  Input,
  InputNumber,
  Select,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BankIcon from "../../../assets/icons/BankIcon";
import DateIcon from "../../../assets/icons/DateIcon";
import DeleteIcon from "../../../assets/icons/DeleteIcon";
import DocumentIcon from "../../../assets/icons/DocumentIcon";
import EmailIcon from "../../../assets/icons/EmailIcon";
import PasswordLock from "../../../assets/icons/PasswordLock";
import PlusIcon from "../../../assets/icons/PlusIcon";
import SelectDownIcon from "../../../assets/icons/SelectDownIcon";
import TowerIcon from "../../../assets/icons/TowerIcon";
import UsersIcon from "../../../assets/icons/UsersIcon";
import CustomForm from "../../../components/common/CustomForm";
import FileUpload from "../../../components/common/FileUpload";
import InputTitle from "../../../components/common/InputTitle";
import Loader from "../../../components/common/Loader";
import { ArrayOption, selectSearchOption } from "../../../helpers/siteConfig";
import AdminLayout from "../../../layouts/AdminLayout";
import { getAllBankAsync } from "../../../store/features/admin/bank/bankAPI";
import { getAllBranchAsync } from "../../../store/features/admin/branch/branchAPI";
import { getAllTowerAsync } from "../../../store/features/admin/network/networkAPI";
import { createProprietorAsync } from "../../../store/features/admin/proprietors/proprietorsAPI";
import {
  getAllAreaAsync,
  getAllRegionAsync,
  getAllTerritoryAsync,
} from "../../../store/features/NP/geoInfo/geoAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { AreaData, GetBranchParams } from "../../../types/redux";
import { disableTodayAndFutureDates, formatDate } from "../../../utils/time";

const navLink = "/admin/proprietors-manage";
const acceptFile = ".jpg, .jpeg, .pdf, .png";
const fileExtra = (
  <span className="text-accent text-[13px] font-normal font-roboto leading-normal">
    File Format: jpg /jpeg/pdf/png | File size('250-500')KB
  </span>
);

export default function CreateProprietorsManage() {
  const { id, viewId } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectBank, setSelectBank] = useState("");
  const [selectRegion, setSelectRegion] = useState("");
  const [selectArea, setSelectArea] = useState("");
  const [selectOthersRegion, setSelectOthersRegion] = useState("");
  const [selectOthersArea, setSelectOthersArea] = useState("");
  const [selectOthersTerritory, setSelectOthersTerritory] = useState("");
  const { allTowers } = useAppSelector((state) => state.network);
  const { allBanks, loading: bankLoading } = useAppSelector(
    (state) => state.bank
  );
  const { updateLoading, postLoading } = useAppSelector(
    (state) => state.proprietor
  );
  // const { allBranch, loading: branchLoading } = useAppSelector(
  //   (state) => state.branch
  // );

  const {
    regions,
    areas,
    territorys,
    loading,
    viewLoading,
    othersRegions,
    othersAreas,
    othersTerritorys,
  } = useAppSelector((state) => state.npGeo);

  const onFinish: FormProps<any>["onFinish"] = (values) => {
    const {
      region,
      area,
      teritory,
      dob,
      document,
      towerId,
      towerRegion,
      towerArea,
      towerTerritory,
      paymentBankInfo,
      branch,
      ...rest
    } = values;

    const newValue = {
      ...rest,
      region,
      area,
      teritory,
      towerId,
      regionName: regions?.find((el) => el?._id === region)?.regionName || "",
      areaName: areas?.find((el) => el?._id === area)?.areaName || "",
      teritoryName:
        territorys?.find((el) => el?._id === teritory)?.teritoryName || "",
      towerName: allTowers?.find((el) => el?._id === towerId)?.towerName || "",
      dob: formatDate(dob?.format(), "YYYY-MM-DD"),
      paymentBankInfo: {
        ...paymentBankInfo,
        branch: "66c2fdf3a2854b00075c8341",
        bankName:
          allBanks?.find((el) => el?._id === paymentBankInfo?.bank)?.name || "",
        // branchName: allBranch?.find((el) => el?._id === branch)?.name || "",
      },
      document: {
        ...document,
        aggrementPaperExpireDate: formatDate(
          document?.aggrementPaperExpireDate?.format(),
          "YYYY-MM-DD"
        ),
        // taxCertificateExpiryDate: formatDate(
        //   document?.taxCertificateExpiryDate?.format(),
        //   "YYYY-MM-DD"
        // ),
      },
    };

    if (id) {
      // dispatch(
      //   updateAreaAsync({
      //     data: values,
      //     others: {
      //       reRender,
      //       id,
      //     },
      //   })
      // );
    } else {
      dispatch(
        createProprietorAsync({
          data: newValue,
          others: {
            reRender,
          },
        })
      );
    }
  };

  // redirect and re-render function
  const reRender = (val: boolean) => {
    if (val) navigate(navLink);
  };

  useEffect(() => {
    dispatch(getAllRegionAsync({}));
    dispatch(
      getAllTowerAsync({
        data: {
          filter: [],
          towerName: "",
          teritory: selectOthersTerritory,
        },
        others: { limit: 10000, skip: 0 },
      })
    );
  }, [dispatch, selectOthersTerritory]);

  useEffect(() => {
    dispatch(getAllAreaAsync({ params: { region: selectRegion } }));
  }, [dispatch, selectRegion]);
  useEffect(() => {
    dispatch(getAllTerritoryAsync({ params: { area: selectArea } }));
  }, [dispatch, selectArea]);

  useEffect(() => {
    dispatch(getAllRegionAsync({ params: { isOther: true } }));
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      getAllAreaAsync({ params: { region: selectOthersRegion, isOther: true } })
    );
  }, [dispatch, selectOthersRegion]);
  useEffect(() => {
    dispatch(
      getAllTerritoryAsync({
        params: { area: selectOthersArea, isOther: true },
      })
    );
  }, [dispatch, selectOthersArea]);

  // useEffect(() => {
  //   if ((id || viewId) && singleArea && updateLoading === false) {
  //     form.setFieldsValue({
  //       region: singleArea?.region?._id,
  //       areaName: singleArea?.areaName,
  //     });
  //   }
  // }, [form, id, singleArea, updateLoading, viewId]);

  // useEffect(() => {
  //   if (id || viewId) {
  //     dispatch(getSingleRegionAsync({ params: { id: id || viewId || "" } }));
  //   }
  // }, [dispatch, id, viewId]);

  // useEffect(() => {
  //   if (id || viewId) {
  //     dispatch(getSingleAreaAsync({ params: { id: id || viewId || "" } }));
  //   }
  // }, [dispatch, id, viewId]);

  useEffect(() => {
    dispatch(getAllBankAsync(null));
  }, [dispatch]);

  useEffect(() => {
    const params: GetBranchParams = {
      bank: selectBank,
    };

    dispatch(
      getAllBranchAsync({
        params,
      })
    );
  }, [selectBank, dispatch]);

  const title = id
    ? "Update Proprietor"
    : viewId
    ? "View Proprietor"
    : "Add New Proprietor";

  return (
    <AdminLayout title={title}>
      {viewLoading ? <Loader /> : null}
      <CustomForm<AreaData>
        onFinish={onFinish}
        title={title}
        backLink={navLink}
        disabled={postLoading || updateLoading}
        isUpdate={id ? true : false}
        hideBtn={viewId ? true : false}
        disabledSubmit={viewLoading}
        formProps={{
          form,
        }}
        formTitle={
          <span className="text-primary flex items-center gap-2">
            <UsersIcon />
            <span className="text-lg font-medium font-roboto leading-[30px] mt-1">
              Proprietor’s Information
            </span>
          </span>
        }
      >
        <div className="grid md:grid-cols-2 gap-x-6">
          <Form.Item
            name="fullName"
            label="Proprietor’s full name"
            rules={[
              {
                required: true,
                message: "Please enter full name",
              },
            ]}
          >
            <Input size="large" placeholder="Enter full name" />
          </Form.Item>
          <Form.Item
            name="fatherName"
            label="Father’s name"
            rules={[
              {
                required: true,
                message: "Please enter father’s name",
              },
            ]}
          >
            <Input size="large" placeholder="Enter full name" />
          </Form.Item>
          <Form.Item
            name="montherName"
            label="Mother’s name"
            rules={[
              {
                required: true,
                message: "Please enter mother’s name",
              },
            ]}
          >
            <Input size="large" placeholder="Enter full name" />
          </Form.Item>
          <Form.Item
            name="dob"
            label="Date of Birth"
            rules={[
              {
                required: true,
                message: "Please enter date of birth",
              },
            ]}
          >
            <DatePicker
              format="DD/MM/YYYY"
              style={{ width: "100%" }}
              placeholder="dd/mm/yyyy"
              suffixIcon={<DateIcon />}
              disabledDate={disableTodayAndFutureDates}
            />
          </Form.Item>
          <Form.Item
            name="nid"
            label="NID Number"
            rules={[
              {
                required: true,
                message: "Please enter NID number",
              },
              {
                validator: async (_: any, value: number) => {
                  if (!value) {
                    return Promise.resolve();
                  }

                  if (value < 0) {
                    return Promise.reject(new Error("NID can't be negative"));
                  }

                  if (!Number.isInteger(value)) {
                    return Promise.reject(new Error("NID must be an integer"));
                  }

                  if (value.toString().length < 10) {
                    return Promise.reject(
                      new Error("NID should be minimum 10")
                    );
                  }
                  if (value.toString().length > 20) {
                    return Promise.reject(
                      new Error("NID should be maximum 20")
                    );
                  }
                },
              },
            ]}
          >
            <InputNumber
              className="w-full"
              size="large"
              placeholder="xxx xxxx xxxxx"
            />
          </Form.Item>
          <Form.Item
            name="passportNumber"
            label="Passport Number"
            rules={[
              // {
              //   required: true,
              //   message: "Please enter passport number",
              // },
              {
                min: 6,
                message: "Passport should be minimum 6",
              },
              {
                max: 15,
                message: "Passport should be maximum 15",
              },
            ]}
          >
            <Input size="large" placeholder="xx-xxxxxxxx" />
            {/* <InputNumber
              className="w-full"
              size="large"
              placeholder="xx-xxxxxxxx"
            /> */}
          </Form.Item>
          <Form.Item
            name="region"
            label="Region"
            rules={[
              {
                required: true,
                message: "Please select region",
              },
            ]}
          >
            <Select
              placeholder="Select"
              size="large"
              allowClear
              showSearch
              loading={loading}
              filterOption={selectSearchOption}
              options={ArrayOption(regions, "regionName", "_id")}
              onChange={(val) => {
                setSelectRegion(val);
                setSelectArea("");
                form.setFieldValue("area", undefined);
                form.setFieldValue("teritory", undefined);
              }}
              disabled={id || viewId ? true : false}
              suffixIcon={<SelectDownIcon />}
            />
          </Form.Item>
          <Form.Item
            name="area"
            label="Area"
            rules={[
              {
                required: true,
                message: "Please select area",
              },
            ]}
          >
            <Select
              placeholder="Select"
              size="large"
              allowClear
              showSearch
              loading={loading}
              filterOption={selectSearchOption}
              options={ArrayOption(areas, "areaName", "_id")}
              onChange={(val) => {
                setSelectArea(val);
                form.setFieldValue("teritory", undefined);
              }}
              disabled={id || viewId || !selectRegion ? true : false}
              suffixIcon={<SelectDownIcon />}
            />
          </Form.Item>
          <Form.Item
            name="teritory"
            label="Territory"
            rules={[
              {
                required: true,
                message: "Please select territory",
              },
            ]}
          >
            <Select
              placeholder="Select"
              size="large"
              allowClear
              showSearch
              loading={loading}
              filterOption={selectSearchOption}
              options={ArrayOption(territorys, "teritoryName", "_id")}
              disabled={id || viewId || !selectArea ? true : false}
              suffixIcon={<SelectDownIcon />}
            />
          </Form.Item>
          <Form.Item
            name="address"
            label="Address"
            rules={[
              {
                required: true,
                message: "Please enter address",
              },
            ]}
          >
            <Input size="large" placeholder="Enter address" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email Address"
            rules={[
              {
                required: true,
                message: "Please enter email",
              },
              {
                validator: async (_: any, value: string) => {
                  if (!value) {
                    return Promise.resolve();
                  }

                  const emailRegex =
                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                  if (!emailRegex.test(value)) {
                    return Promise.reject(
                      new Error("Please enter a valid email")
                    );
                  }
                },
              },
            ]}
          >
            <Input
              size="large"
              prefix={<EmailIcon />}
              placeholder="example@email.com"
            />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please enter password",
              },
              {
                min: 8,
                message: "Password must be minimum 8 characters.",
              },
            ]}
          >
            <Input.Password
              prefix={<PasswordLock />}
              size="large"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item
            name="activeMobileNumber"
            label="Active Mobile Number"
            rules={[
              {
                required: true,
                message: "Please enter mobile no",
              },
              {
                pattern: /^(\+88)?(01[3-9])[0-9]{8}$/,
                message: "Please enter a valid Bangladeshi phone number",
              },
            ]}
          >
            <Input size="large" prefix={"+88 "} placeholder="xxx xxxx xxxxx" />
          </Form.Item>
          <Form.List initialValue={[undefined]} name="optionalMobileNumber">
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map((field, index) => (
                    <div
                      // grid grid-cols-7 items-center
                      className="relative"
                      key={field.key}
                    >
                      <Form.Item
                        name={[index]}
                        label="Optional Mobile Number"
                        rules={[
                          {
                            pattern: /^(\+88)?(01[3-9])[0-9]{8}$/,
                            message:
                              "Please enter a valid Bangladeshi phone number",
                          },
                        ]}
                        // className={
                        //   fields.length > 1 ? "col-span-6" : "col-span-7"
                        // }
                        // className="col-span-7"
                      >
                        <Input
                          size="large"
                          prefix={"+88 "}
                          placeholder="xxx xxxx xxxxx"
                        />
                      </Form.Item>

                      {fields.length > 1 ? (
                        <div className="flex justify-end mt-1 absolute right-0 -top-0">
                          <button
                            // bg-[#FFDCDA] w-8 h-8
                            className="rounded-full bg-transparent border-none cursor-pointer"
                            onClick={() => remove(field.name)}
                          >
                            <span className="relative top-[1px]">
                              <DeleteIcon />
                            </span>
                          </button>
                        </div>
                      ) : null}
                    </div>
                  ))}

                  <div
                    className={`flex items-center mb-3 ${
                      fields?.length % 2 !== 0 ? "mt-2" : "mt-5"
                    }`}
                  >
                    <Tooltip placement="top" title={"Add more mobile number"}>
                      <button
                        type="button"
                        className="w-[45px] h-[45px] p-4 bg-white rounded-md justify-center items-center flex border border-solid border-others-border cursor-pointer bg-white/60 hover:shadow"
                        onClick={() => add()}
                      >
                        <PlusIcon />
                      </button>
                    </Tooltip>
                  </div>
                  {/* <Button type="primary" onClick={() => add()}>
                      <PlusOutlined /> Add Contact
                    </Button> */}
                </>
              );
            }}
          </Form.List>
        </div>
        <div className="mt-1">
          <InputTitle icon={<TowerIcon />} title="Tower Information" />
          <div className="grid md:grid-cols-2 gap-x-6 pt-1">
            <Form.Item
              name="towerRegion"
              label="Region"
              rules={[
                {
                  required: true,
                  message: "Please select region",
                },
              ]}
            >
              <Select
                placeholder="Select"
                size="large"
                allowClear
                showSearch
                loading={loading}
                filterOption={selectSearchOption}
                options={ArrayOption(othersRegions, "regionName", "_id")}
                onChange={(val) => {
                  setSelectOthersRegion(val);
                  setSelectOthersArea("");
                  setSelectOthersTerritory("");
                  form.setFieldValue("towerArea", undefined);
                  form.setFieldValue("towerTerritory", undefined);
                  form.setFieldValue("towerId", undefined);
                }}
                disabled={id || viewId ? true : false}
                suffixIcon={<SelectDownIcon />}
              />
            </Form.Item>
            <Form.Item
              name="towerArea"
              label="Area"
              rules={[
                {
                  required: true,
                  message: "Please select area",
                },
              ]}
            >
              <Select
                placeholder="Select"
                size="large"
                allowClear
                showSearch
                loading={loading}
                filterOption={selectSearchOption}
                options={ArrayOption(othersAreas, "areaName", "_id")}
                onChange={(val) => {
                  setSelectOthersArea(val);
                  setSelectOthersTerritory("");
                  form.setFieldValue("towerTerritory", undefined);
                  form.setFieldValue("towerId", undefined);
                }}
                disabled={id || viewId || !selectOthersRegion ? true : false}
                suffixIcon={<SelectDownIcon />}
              />
            </Form.Item>
            <Form.Item
              name="towerTerritory"
              label="Territory"
              rules={[
                {
                  required: true,
                  message: "Please select territory",
                },
              ]}
            >
              <Select
                placeholder="Select"
                size="large"
                allowClear
                showSearch
                loading={loading}
                filterOption={selectSearchOption}
                options={ArrayOption(othersTerritorys, "teritoryName", "_id")}
                disabled={id || viewId || !selectOthersArea ? true : false}
                suffixIcon={<SelectDownIcon />}
                onChange={(val) => {
                  setSelectOthersTerritory(val);
                  form.setFieldValue("towerId", undefined);
                }}
              />
            </Form.Item>
            <Form.Item
              name="towerId"
              label="Tower"
              rules={[
                {
                  required: true,
                  message: "Please select tower",
                },
              ]}
            >
              <Select
                placeholder="Select"
                size="large"
                allowClear
                showSearch
                loading={loading}
                filterOption={selectSearchOption}
                options={ArrayOption(allTowers, "towerName", "_id", true)}
                disabled={!selectOthersTerritory || viewId ? true : false}
                suffixIcon={<SelectDownIcon />}
              />
            </Form.Item>
          </div>
        </div>

        <div className="mt-1">
          <InputTitle
            icon={<BankIcon />}
            className="!mt-0"
            title="Payment & Bank Information"
          />
          <div className="grid md:grid-cols-2 gap-x-6 pt-1">
            <Form.Item
              name={["paymentBankInfo", "advanceAmount"]}
              label="Advance Amount"
              rules={[
                {
                  required: true,
                  message: "Please enter advance amount",
                },
                {
                  validator: async (_: any, value: number) => {
                    if (!value) {
                      return Promise.resolve();
                    }

                    if (value < 0) {
                      return Promise.reject(
                        new Error("Advance amount can't be negative")
                      );
                    }

                    if (!Number.isInteger(value)) {
                      return Promise.reject(
                        new Error("Advance amount must be an integer")
                      );
                    }
                  },
                },
              ]}
            >
              <InputNumber
                className="w-full"
                size="large"
                placeholder="00.00 TK"
                addonAfter={<BankIcon />}
              />
            </Form.Item>
            <Form.Item
              name={["paymentBankInfo", "monthlyRentAmount"]}
              label="Monthly Rent Amount"
              rules={[
                {
                  required: true,
                  message: "Please enter rent amount",
                },
                {
                  validator: async (_: any, value: number) => {
                    if (!value) {
                      return Promise.resolve();
                    }

                    if (value < 0) {
                      return Promise.reject(
                        new Error("Monthly rent amount can't be negative")
                      );
                    }

                    if (!Number.isInteger(value)) {
                      return Promise.reject(
                        new Error("Monthly rent amount must be an integer")
                      );
                    }
                  },
                },
              ]}
            >
              <InputNumber
                className="w-full"
                size="large"
                placeholder="00.00 TK"
                addonAfter={<BankIcon />}
              />
            </Form.Item>

            <Form.Item
              name={["paymentBankInfo", "bank"]}
              label="Your Bank"
              rules={[
                {
                  required: true,
                  message: "Please select bank",
                },
              ]}
            >
              <Select
                placeholder="Select bank name"
                size="large"
                allowClear
                showSearch
                loading={bankLoading}
                filterOption={selectSearchOption}
                options={ArrayOption(allBanks, "name", "_id")}
                disabled={viewId ? true : false}
                suffixIcon={<SelectDownIcon />}
                onChange={(val) => {
                  setSelectBank(val);
                  form.setFieldsValue({ branch: undefined });
                }}
              />
            </Form.Item>
            <Form.Item
              name={["paymentBankInfo", "accountTitle"]}
              label="Account Title"
              rules={[
                {
                  required: true,
                  message: "Please enter account title",
                },
              ]}
            >
              <Input size="large" placeholder="Account title" />
            </Form.Item>
            <Form.Item
              name={["paymentBankInfo", "accountNumber"]}
              label="Account Number"
              rules={[
                {
                  required: true,
                  message: "Please enter account number",
                },
                {
                  validator: async (_: any, value: number) => {
                    if (!value) {
                      return Promise.resolve();
                    }

                    if (value < 0) {
                      return Promise.reject(
                        new Error("Account number can't be negative")
                      );
                    }

                    if (!Number.isInteger(value)) {
                      return Promise.reject(
                        new Error("Account number must be an integer")
                      );
                    }

                    if (value.toString().length < 12) {
                      return Promise.reject(
                        new Error("Account number should be minimum 12")
                      );
                    }
                    if (value.toString().length > 17) {
                      return Promise.reject(
                        new Error("Account number should be maximum 17")
                      );
                    }
                  },
                },
              ]}
            >
              <InputNumber
                className="w-full"
                size="large"
                placeholder="XXX XXXX XXXX XXXXX"
              />
            </Form.Item>
            <Form.Item
              name={["paymentBankInfo", "routingNumber"]}
              label="Routing Number"
              rules={[
                {
                  required: true,
                  message: "Please enter routing number",
                },
                {
                  validator: async (_: any, value: number) => {
                    if (!value) {
                      return Promise.resolve();
                    }

                    if (value < 0) {
                      return Promise.reject(
                        new Error("Routing number can't be negative")
                      );
                    }

                    if (!Number.isInteger(value)) {
                      return Promise.reject(
                        new Error("Routing number must be an integer")
                      );
                    }

                    if (value.toString().length < 6) {
                      return Promise.reject(
                        new Error("Routing number should be minimum 6")
                      );
                    }
                    if (value.toString().length > 9) {
                      return Promise.reject(
                        new Error("Routing number should be maximum 9")
                      );
                    }
                  },
                },
              ]}
            >
              <InputNumber
                className="w-full"
                size="large"
                placeholder="XXX XXXX"
              />
            </Form.Item>
            <Form.Item
              name={["paymentBankInfo", "branchName"]}
              label="Branch Name"
              rules={[
                {
                  required: true,
                  message: "Please enter branch name",
                },
              ]}
            >
              <Input size="large" placeholder="Enter branch name" />

              {/* <Select
                placeholder="Select"
                size="large"
                allowClear
                showSearch
                loading={branchLoading}
                filterOption={selectSearchOption}
                options={ArrayOption(allBranch, "name", "_id")}
                disabled={viewId || !selectBank ? true : false}
                suffixIcon={<SelectDownIcon />}
              /> */}
            </Form.Item>

            <Form.Item
              name="nextPaymentDate"
              label="Next Payment Date"
              rules={[
                {
                  required: true,
                  message: "Please enter next payment date",
                },
              ]}
            >
              <DatePicker
                format="DD/MM/YYYY"
                style={{ width: "100%" }}
                placeholder="dd/mm/yyyy"
                suffixIcon={<DateIcon />}
                // disabledDate={disableTodayAndFutureDates}
              />
            </Form.Item>
          </div>
        </div>
        <div className="mt-1">
          <InputTitle
            icon={
              <span className="-ml-1">
                <DocumentIcon />
              </span>
            }
            title="Document Attachment"
          />

          <div className="grid md:grid-cols-3 gap-x-6 pt-1">
            <Form.Item
              name={["document", "aggrementPaper"]}
              label="Agreement Paper"
              extra={fileExtra}
              valuePropName="fileUrl"
              rules={[
                {
                  required: true,
                  message: "Please upload agreement paper",
                },
              ]}
            >
              <FileUpload
                form={form}
                accept={acceptFile}
                name="document.aggrementPaper"
                onChange={(url) =>
                  form.setFieldsValue({ "document.aggrementPaper": url })
                }
              />
            </Form.Item>

            <Form.Item
              name={["document", "aggrementPaperExpireDate"]}
              label="Expire Date of Agreement"
              rules={[
                {
                  required: true,
                  message: "Please enter expire date of agreement",
                },
              ]}
            >
              <DatePicker
                format="DD/MM/YYYY"
                style={{ width: "100%" }}
                placeholder="dd/mm/yyyy"
                suffixIcon={<DateIcon />}
              />
            </Form.Item>

            <Form.Item
              name={["yearlyPaymentNumber"]}
              label="Yearly Payment Frequency"
              rules={[
                {
                  required: true,
                  message: "Please enter yearly payment frequency",
                },

                {
                  validator: async (_: any, value: number) => {
                    if (value === undefined || value === null) {
                      return Promise.resolve();
                    }

                    if (value === 0) {
                      return Promise.reject(
                        new Error("Payment frequency can't be zero")
                      );
                    }

                    if (value < 1) {
                      return Promise.reject(
                        new Error("Payment frequency can't be negative")
                      );
                    }
                    if (value > 12) {
                      return Promise.reject(
                        new Error("Payment frequency can be maximum 12")
                      );
                    }

                    if (!Number.isInteger(value)) {
                      return Promise.reject(
                        new Error("Payment frequency must be an integer")
                      );
                    }
                  },
                },
              ]}
            >
              <InputNumber
                className="w-full"
                size="large"
                placeholder="Payment frequency"
                // addonAfter={<BankIcon />}
              />
            </Form.Item>
          </div>
          <div className="grid md:grid-cols-3 gap-x-6 pt-1">
            {/* <Form.Item
              name={["document", "TaxCertificate"]}
              label="Tax return"
              extra={fileExtra}
              valuePropName="fileUrl"
              rules={[
                {
                  required: true,
                  message: "Please upload tax return",
                },
              ]}
            >
              <FileUpload
                form={form}
                accept={acceptFile}
                name="document.TaxCertificate"
                onChange={(url) =>
                  form.setFieldsValue({ "document.TaxCertificate": url })
                }
              />
            </Form.Item>

            <Form.Item
              name={["document", "taxNumber"]}
              label="Return registration number"
              rules={[
                {
                  required: true,
                  message: "Please enter return registration number",
                },
                {
                  validator: async (_: any, value: number) => {
                    if (!value) {
                      return Promise.resolve();
                    }

                    if (value === 0) {
                      return Promise.reject(
                        new Error("Return registration number can't be zero")
                      );
                    }

                    if (value < 0) {
                      return Promise.reject(
                        new Error(
                          "Return registration number can't be negative"
                        )
                      );
                    }

                    if (!Number.isInteger(value)) {
                      return Promise.reject(
                        new Error(
                          "Return registration number must be an integer"
                        )
                      );
                    }
                  },
                },
              ]}
            >
              <InputNumber
                className="w-full"
                size="large"
                placeholder="XXX XXXX"
              />
            </Form.Item>

            <Form.Item
              name={["document", "taxCertificateExpiryDate"]}
              label="Fiscal year"
              rules={[
                {
                  required: true,
                  message: "Please enter fiscal year",
                },
              ]}
            >
              <Select
                placeholder="Select fiscal year"
                size="large"
                allowClear
                showSearch
                loading={bankLoading}
                filterOption={selectSearchOption}
                options={ArrayOption(generateFiscalYears(), "key", "value")}
                disabled={viewId ? true : false}
                suffixIcon={<SelectDownIcon />}
                
              />

            </Form.Item> */}

            {/* <Form.Item
              name={["document", "taxCertificateExpiryDate"]}
              label="Expire Date of Tax"
              rules={[
                {
                  required: true,
                  message: "Please enter expire date of tax",
                },
              ]}
            >
              <DatePicker
                format="DD/MM/YYYY"
                style={{ width: "100%" }}
                placeholder="dd/mm/yyyy"
                suffixIcon={<DateIcon />}
              />
            </Form.Item> */}
          </div>
          <div className="grid md:grid-cols-2 gap-x-6 pt-1">
            <Form.Item
              name={["document", "TinCertificate"]}
              label="TIN Certificate"
              extra={fileExtra}
              valuePropName="fileUrl"
              rules={[
                {
                  required: true,
                  message: "Please upload TIN certificate",
                },
              ]}
            >
              <FileUpload
                form={form}
                accept={acceptFile}
                name="document.TinCertificate"
                onChange={(url) =>
                  form.setFieldsValue({ "document.TinCertificate": url })
                }
              />
            </Form.Item>

            <Form.Item
              name={["document", "tinNumber"]}
              label="TIN Number"
              rules={[
                {
                  required: true,
                  message: "Please enter TIN number",
                },
                {
                  validator: async (_: any, value: number) => {
                    if (!value) {
                      return Promise.resolve();
                    }

                    if (value === 0) {
                      return Promise.reject(
                        new Error("TIN number can't be zero")
                      );
                    }

                    if (value !== 9) {
                      return Promise.reject(new Error("Invalid TIN number"));
                    }

                    if (value < 0) {
                      return Promise.reject(
                        new Error("TIN number can't be negative")
                      );
                    }

                    if (!Number.isInteger(value)) {
                      return Promise.reject(
                        new Error("TIN number must be an integer")
                      );
                    }
                  },
                },
              ]}
            >
              <InputNumber
                className="w-full"
                size="large"
                placeholder="XXX XXXX"
                // addonAfter={<BankIcon />}
              />
            </Form.Item>

            <Form.Item
              name={["document", "nid"]}
              label="NID"
              extra={fileExtra}
              valuePropName="fileUrl"
              rules={[
                {
                  required: true,
                  message: "Please upload NID",
                },
              ]}
            >
              <FileUpload
                form={form}
                accept={acceptFile}
                name="document.nid"
                onChange={(url) => form.setFieldsValue({ "document.nid": url })}
              />
            </Form.Item>
            {/* <Form.Item
              name={["nidNumber"]}
              label="NID Number"
              rules={[
                {
                  required: true,
                  message: "Please enter NID number",
                },
                {
                  validator: async (_: any, value: number) => {
                    if (!value) {
                      return Promise.resolve();
                    }

                    if (value === 0) {
                      return Promise.reject(
                        new Error("NID number can't be zero")
                      );
                    }

                    if (value < 0) {
                      return Promise.reject(
                        new Error("NID number can't be negative")
                      );
                    }

                    if (!Number.isInteger(value)) {
                      return Promise.reject(
                        new Error("NID number must be an integer")
                      );
                    }
                  },
                },
              ]}
            >
              <InputNumber
                className="w-full"
                size="large"
                placeholder="NID number"
                // addonAfter={<BankIcon />}
              />
            </Form.Item> */}

            <Form.Item
              name={["document", "passport"]}
              label="Passport"
              extra={fileExtra}
              valuePropName="fileUrl"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please upload Passport",
              //   },
              // ]}
            >
              <FileUpload
                form={form}
                accept={acceptFile}
                name="document.passport"
                onChange={(url) =>
                  form.setFieldsValue({ "document.passport": url })
                }
              />
            </Form.Item>
            <Form.Item
              name="photo"
              label="Proprietor’s Photo"
              extra={
                <span className="text-accent text-[13px] font-normal font-roboto leading-normal">
                  File Format: jpg /jpeg/png | File size('250-500')KB
                </span>
              }
              valuePropName="fileUrl"
              rules={[
                {
                  required: true,
                  message: "Please upload proprietor’s photo",
                },
              ]}
            >
              <FileUpload
                form={form}
                accept={".jpg, .jpeg, .png"}
                name="photo"
                onChange={(url) => form.setFieldsValue({ photo: url })}
              />
            </Form.Item>

            {/* <div className="flex items-center">
              <button
                type="button"
                className="w-[50px] h-[50px] p-4 bg-white rounded-md justify-center items-center flex border border-solid border-others-border cursor-pointer bg-white/60 hover:shadow -mt-3"
              >
                <PlusIcon />
              </button>
            </div> */}

            {/* <div className="w-[50px] h-[50px] p-4 bg-white rounded-md border border-[#afbaca] justify-center items-center gap-2.5 inline-flex">
              <div className="justify-start items-start gap-2.5 flex">
                <div className="w-[18px] h-[18px] p-[2.81px] justify-center items-center flex" />
              </div>
            </div> */}
          </div>
        </div>
      </CustomForm>
    </AdminLayout>
  );
}
